.required-asterisk {
  color: red;
}
:root{
  --green-text:#2faf6f;
  --green-background:#d8ffeb;
  --red-text:#FF1414;
  --red-background:#ffebeb;
  --blue-text:#1B9DF8;
  --blue-background:#EBF6FE;
  --black:#494949;
}
/* .MuiOutlinedInput-input {
  font-size: 13px !important;
  color: #8a8a8a !important;
} */

/* .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ececec !important;
} */

/* .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid #26ab69 !important;
} */

/* .MuiOutlinedInput-notchedOutline:focus-within {
  border: 1px solid #26ab69 !important;
} */

/* .MuiFormControl-root > div > div:hover {
  border: 1px solid #26ab69;
  box-shadow: none;
} */
 
/* 
.MuiFormControl-root > div > div:focus-within {
  border: 1px solid #26ab69 !important;
  box-shadow: none;
} */

.ScrollbarsCustom-Scroller {
  padding-right: 12px !important;
}
