/* .phoneInput {
  font-size: 16px !important;

  border-bottom: 1px solid #EFEFEF !important; 
  box-shadow: 0px 0px 2px 0px #0000001a; 
  color: #111111 !important;
  margin-bottom: 10px; 
  font-family: 'Poppins', sans-serif;
  height: 35px; 
  padding: 8px; 
  outline: none; 
}

.phoneInput .PhoneInputInput,
.phoneInput .PhoneInputInput:focus,
.phoneInput .PhoneInputInput:hover {
  font-size: 12px !important;
  color: #27ab69 !important;
  background-color: transparent;
  border: none !important; 
  outline: none !important; 
}  */



.phoneInput {
  font-size: 16px !important;
  color: #111111 !important;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  height: 35px;
  border-color: #F6F6F6;
  padding: 8px;
  outline: none !important;
  border:none !important;
  border-bottom: 1px solid #E4E4E4 !important; 
}
.phoneInput:hover {
  border-bottom: 2px solid #111111 !important;
} 
.phoneInput:focus {
  border-bottom: 2px solid #27AB69 !important;
} 

.phoneInput .PhoneInputInput,
.phoneInput .PhoneInputInput:focus,
.phoneInput .PhoneInputInput:hover,
.phoneInput input[type="tel"] {
  font-size: 14px !important;
  color: #111111 !important;
  background-color: transparent;
  border: none !important; 
  outline: none !important;
}


